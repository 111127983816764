import React from "react";
import ProjectLanguages from "../../components/projectLanguages/ProjectLanguages";
import "./GithubRepoCard.css";
import { Fade } from "react-reveal";

export default function GithubRepoCard({ repo, theme }) {
  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="githubrepo cert-card">
        <div className="content">
          <a href={repo.link} target="_blank" rel="noopener noreferrer">
            <div className="content-overlay"></div>
            <div
              className="cert-header"
              style={{ backgroundColor: repo.color_code }}
            >
              <img
                className="logo_img"
                src={require(`../../assests/images/${repo.imageSrc}`)}
                alt={repo.alt_name}
              />
            </div>
            <div className="content-details fadeIn-top">
              <h3 className="content-title" style={{ color: theme.body }}>
                GitHub
              </h3>
            </div>
          </a>
        </div>
        <div className="cert-body">
          <h2 className="cert-body-title" style={{ color: theme.text }}>
            {repo.title}
          </h2>
          <h3
            className="cert-body-subtitle"
            style={{ color: theme.secondaryText }}
          >
            {repo.description}
          </h3>
          <div>
            <ProjectLanguages
              className="repo-languages"
              logos={repo.languages}
              link={repo.viewlink}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}
