import React from "react";
import "./LoaderLogo.css";
import { BoltLoader } from "react-awesome-loaders-py3";
class LogoLoader extends React.Component {
  render() {
    // const theme = this.props.theme;
    return (
      <>
        <BoltLoader
          className={"loaderbolt"}
          boltColor={"#FFFF00"}
          backgroundBlurColor={"#E0E7FF"}
        />
      </>
    );
  }
}

export default LogoLoader;
