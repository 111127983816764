import React, { Component } from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import "./ExperienceAccordion.css";
import { Accordion, Panel } from "baseui/accordion";

class ExperienceAccordion extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-accord">
        <Accordion>
          {this.props.sections.map((section, index) => {
            const sectionKey = section.id || index; // Use section id if available, otherwise use index
            return (
              <Panel
                className="accord-panel"
                title={section.title}
                key={sectionKey}
                overrides={{
                  Header: {
                    style: () => ({
                      backgroundColor: `${theme.body}`,
                      border: `1px solid`,
                      borderRadius: `5px`,
                      borderColor: `${theme.headerColor}`,
                      marginBottom: `3px`,
                      fontFamily: "Google Sans Regular",
                      color: `${theme.text}`,
                      ":hover": {
                        color: `${theme.secondaryText}`,
                      },
                    }),
                  },
                  Content: {
                    style: () => ({
                      backgroundColor: `${theme.body}`,
                    }),
                  },
                }}
              >
                {/* {section.experiences.map((experience, index) => (
                  <ExperienceCard
                    key={index} // Assuming index is unique within experiences array
                    index={index}
                    totalCards={section.experiences.length}
                    experience={experience}
                    theme={theme}
                  />
                ))} */}
                {section.experiences.length > 0 ? (
                  section.experiences.map((experience, experienceIndex) => (
                    <ExperienceCard
                      key={experienceIndex} // Assuming index is unique within experiences array
                      index={experienceIndex}
                      totalCards={section.experiences.length}
                      experience={experience}
                      theme={theme}
                    />
                  ))
                ) : (
                  <div
                  >
                   As a current student, I have not yet gained work experience
                  </div>
                )}
              </Panel>
            );
          })}
        </Accordion>
      </div>
    );
  }
}

export default ExperienceAccordion;
