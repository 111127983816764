/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Rajendra's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Rajendra's Portfolio",
    type: "website",
    url: "http://rajarajendra.live/",
  },
};

//Home Page
const greeting = {
  title: "Raja Rajendra",
  logo_name: "Raja",
  nickname: "King",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/17xXH6_OoEiaO5O_ROYV979Zb_htDDLnw/view?usp=sharing",
  portfolio_repository: "https://github.com/Raja-25/",
  githubProfile: "https://github.com/Raja-25",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/Raja-25",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/rajarajendra/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },

  {
    name: "Gmail",
    link: "mailto:2100032433cseh@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/rajarajendra925",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/raja_.rajendra_/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React",
        "⚡ Using Several Full Stacks like Spring Boot, Mern, Django ",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },

        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          // fontAwesomeClassname: "simple-icons:node-js",
          imageSrc: "node.png",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#339933",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on  AWS",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Oracle",
          fontAwesomeClassname: "simple-icons:oracle",
          style: {
            color: "#E34F26",
          },
        },

        {
          skillName: "MySql",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/kl_2100032433/",
    },

    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/kl_2100032433",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "https://codeforces.com/profile/kl_2100032433",
    },
    {
      siteName: "Hackerrank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#32c766",
      },
      profileLink: "https://www.hackerrank.com/profile/kl_2100032433",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Koneru Lakshmaiah Education Foundation Vijayawada",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "kl.png",
      alt_name: "KLU Vijayawada",
      duration: "2021 - 2025",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CNS, etc.",
        "⚡ Apart from this, I have done courses on Cloud Computing and Full Stack Development.",
        "⚡ Created Multiple Full Stack Projects by using various Stacks like Django, SpringBoot, Mern",
      ],
      website_link: "https://www.kluniversity.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AWS Developer -Associate",
      subtitle: " November 2023 - November 2026",
      logo_path: "adc.png",
      certificate_link:
        "https://www.credly.com/badges/52ba1018-df85-4517-979d-1b2b10517557/public_url",
      alt_name: "ADC",
      color_code: "#8C151599",
    },
    {
      title: "AWS Cloud Practitioner",
      subtitle: " June 2023 - November 2026",
      logo_path: "cp.png",
      certificate_link:
        "https://www.credly.com/badges/79a789ca-e846-446d-8f96-798c88c4e7f3/public_url",
      alt_name: "cp",
      color_code: "#00000099",
    },
    {
      title: "Red Hat Certified Enterprise Application Developer",
      subtitle: "August 2023 - August 2026",
      logo_path: "redhat.png",
      certificate_link:
        "https://www.credly.com/badges/e4cfd1ef-7940-4477-a4d1-ffeaa5ffe69e/public_url",
      alt_name: "RedHat",
      color_code: "#0C9D5899",
    },
    {
      title: "Microsoft AI Fundamentals",
      subtitle: "April 2023",
      logo_path: "azureai.png",
      certificate_link:
        "https://www.credly.com/badges/0c0dd4a0-add7-418c-b923-2a0a986c2c26/public_url",
      alt_name: "microsoft",
      color_code: "#1F70C199",
    },
    {
      title: "AWS Academy Cloud Architecting",
      subtitle: "January 2023",
      logo_path: "academy1.png",
      certificate_link:
        "https://www.credly.com/badges/985769d2-9a45-4fd7-95cc-0f91e55bc882/public_url",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "AWS Academy Cloud Foundations",
      subtitle: "January 2023",
      logo_path: "academy2.png",
      certificate_link:
        "https://www.credly.com/badges/7715c7e1-793c-45b2-95e6-97a16417cef5/public_url",
      alt_name: "Microsoft",
      color_code: "#FFBB0099",
    },
    {
      title: "Demystifying Networking",
      subtitle: "August 2022",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1G6e1m3SkKVzNwZxlm2wcm454RqPnL8N7/view?usp=sharing",
      alt_name: "NPTEL",
      color_code: "#2A73CC",
    },
    {
      title: "Oracle Cloud Infrastructure 2023 Certified Architect Associate",
      subtitle: "August 2023 - August 2025",
      logo_path: "oracle.png",
      certificate_link:
        "https://drive.google.com/file/d/18EUSi0MKrIcvkzqT0Vrg1of2hnPocgyV/view?usp=sharing",
      alt_name: "NPTEL",
      color_code: "#0C9D5899",
    },
    {
      title: "Problem Solving(Basic)",
      subtitle: "December 2021",
      logo_path: "hack1.png",
      certificate_link: "https://www.hackerrank.com/certificates/d4809fd3db57",
      alt_name: "hackerrank",
      color_code: "#8C151599",
    },
    {
      title: "Java(Basic)",
      subtitle: "March 2023",
      logo_path: "hack2.png",
      certificate_link: "https://www.hackerrank.com/certificates/db54c60f388b",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "LeetCode 50 Days",
      subtitle: "December 2023",
      logo_path: "leet.gif",
      certificate_link: "https://leetcode.com/kl_2100032433/",
      alt_name: "NPTEL",
      color_code: "#1F70C199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I am currently a college student and have recently completed a Software Development Engineering (SDE) internship at Barclays. This experience has allowed me to cultivate my skills and contribute meaningfully to the dynamic environment at Barclays.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        // {
        //   title: "Associate AI Engineer",
        //   company: "Legato Health Technology",
        //   company_url: "https://legatohealthtech.com/",
        //   logo_path: "hack2.png",
        //   duration: "June 2020 - Aug 2021",
        //   location: "Hyderabad, Telangana",
        //   description:
        //     "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
        //   color: "#0879bf",
        // },
        // {
        //   title: "Android and ML Developer",
        //   company: "Muffito Incorporation",
        //   company_url: "https://www.linkedin.com/company/muffito-inc/about/",
        //   logo_path: "hack2.png",
        //   duration: "May 2018 - Oct 2018",
        //   location: "Pune, Maharashtra",
        //   description:
        //     "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
        //   color: "#9b1578",
        // },
        // {
        //   title: "Android Developer",
        //   company: "FreeCopy Pvt. Ltd.",
        //   company_url: "https://www.linkedin.com/company/freecopy/about/",
        //   logo_path: "hack2.png",
        //   duration: "Nov 2017 - Dec 2017",
        //   location: "Ahmedabad, Gujarat",
        //   description:
        //     "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
        //   color: "#fc1f20",
        // },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Tech Intern",
          company: "Barclays PLC",
          company_url: "https://home.barclays/",
          logo_path: "barclays.png",
          duration: "June 2024 - July 2024",
          location: "Pune, India",
          description:"Developed an internal automation tool that streamlined the onboarding process for applications onto workload scheduler tools such as Autosys and IBM TWS, utilizing AngularJS, Django, and Oracle. This automation significantly reduced the manual labor time for each request from 60 minutes to just a few minutes. The tool included functionalities for onboarding new applications, as well as modifying and disabling existing ones. Additionally, comprehensive documentation and detailed workflow charts were prepared to ensure seamless usage and maintenance of the tool.",
          color: "#000000",
        },
        
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     // {
    //     //   title: "Google Explore ML Facilitator",
    //     //   company: "Google",
    //     //   company_url: "https://about.google/",
    //     //   logo_path: "hack2.png",
    //     //   duration: "June 2019 - April 2020",
    //     //   location: "Hyderabad, Telangana",
    //     //   description:
    //     //     "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //     //   color: "#4285F4",
    //     // },
    //     // {
    //     //   title: "Microsoft Student Partner",
    //     //   company: "Microsoft",
    //     //   company_url: "https://www.microsoft.com/",
    //     //   logo_path: "hack2.png",
    //     //   duration: "Aug 2019 - May 2020",
    //     //   location: "Hyderabad, Telangana",
    //     //   description:
    //     //     "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //     //   color: "#D83B01",
    //     // },
    //     // {
    //     //   title: "Mozilla Campus Captain",
    //     //   company: "Mozilla",
    //     //   company_url: "https://www.mozilla.org/",
    //     //   logo_path: "hack2.png",
    //     //   duration: "Oct 2019 - May 2020",
    //     //   location: "Kurnool, Andhra Pradesh",
    //     //   description:
    //     //     "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //     //   color: "#000000",
    //     // },
    //     // {
    //     //   title: "Developer Students Club Member",
    //     //   company: "DSC IIITDM Kurnool",
    //     //   company_url:
    //     //     "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //     //   logo_path: "hack2.png",
    //     //   duration: "Jan 2018 - May 2020",
    //     //   location: "Kurnool, Andhra Pradesh",
    //     //   description:
    //     //     "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //     //   color: "#0C9D58",
    //     // },
        
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "",
  avatar_image_path: "projects_image.svg",
};
const projectsdata = {
  data: [
    {
      title: "AutoBots",
      link: "https://github.com/Raja-25/SDP-02.git",
      viewlink: "https://autobotsfrontend.netlify.app/",
      imageSrc: "autobots.gif",
      description:
        "The AutoBots is a MERN stack-based software solution designed to streamline vehicle inventory, sales, customer interactions, and maintenance tasks. It offers intuitive user interfaces and responsive design for seamless access across devices.",
      alt_name: "cp",
      color_code: "#00000099",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "MonogoDb",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      title: "PetNirvana",
      link: "https://github.com/Raja-25/SDP-03.git",
      viewlink: "https://pet-nirvana.netlify.app/",
      imageSrc: "pet.png",
      description:
        "I led a dynamic team for the development of Pet Nirvana, a comprehensive Pet Adoption and Accessories Management System. Harnessing the capabilities of Spring Boot, a robust and efficient platform was crafted to facilitate pet adoptions and manage associated accessories seamlessly.",
      alt_name: "cp",
      color_code: "#00000099",
      languages: [
        {
          name: "SpringBoot",
          iconifyClass: "logos-spring",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      title: "VaayuPrayan",
      link: "https://github.com/Raja-25/VAAYU-An-Initiative.git",
      viewlink: "https://vaayu-travels.netlify.app",
      imageSrc: "vaayu.gif",
      description:
        "During the Smart India Hackathon, I collaborated effectively to develop a cutting-edge Travel and Tourism Website. This AI-integrated platform offers innovative features to enhance user experience, such as personalized travel recommendations, real-time itinerary planning, and interactive chatbots. ",
      alt_name: "cp",
      color_code: "#00000099",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "MonogoDb",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      title: "Rekognition",
      link: "https://github.com/Raja-25/Recognition.git",
      viewlink: "https://rekognition-aws.netlify.app",
      imageSrc: "rekog.png",
      description:
        "A web application built with React utilized AWS services like Rekognition, Translate, S3, and Lex for functionalities such as image recognition, language translation, storage, and conversational interfaces.",
      alt_name: "cp",
      color_code: "#00000099",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Amazon Web Services",
          iconifyClass: "logos-aws",
        },
      ],
    },
    {
      title: "OutLining",
      link: "https://github.com/Raja-25/SDP-01.git",
      viewlink: "",
      imageSrc: "outlining.png",
      description:
        "Led a team in developing a sophisticated Entertainment Management System utilizing the Django framework within the Python stack. This system facilitates comprehensive management of entertainment-related tasks, offering efficiency and scalability in organizing events, talent bookings, and venue management.",
      alt_name: "cp",
      color_code: "#00000099",
      languages: [
        {
          name: "Django",
          iconifyClass: "logos-django",
        },
        {
          name: "SQLite",
          iconifyClass: "logos-sqlite",
        },
      ],
    }, {
      title: "BidBoss",
      link: "https://github.com/Raja-25/TMS.git",
      viewlink: "",
      imageSrc: "ep.png",
      description:
        "Engineered with enterprise programming frameworks, the Tender Management System optimizes procurement processes by efficiently handling tenders and contracts, ensuring enhanced efficiency and effectiveness in the procurement workflow.",
      alt_name: "cp",
      color_code: "#00000099",
      languages: [
        {
          name: "Django",
          iconifyClass: "logos-redhat",
        },
        {
          name: "MYSQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
  ],
};
const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name:
        "Secure Encryption Framework for Multi-Cloud Environments: Leveraging Symmetric and Asymmetric Encryption",
      createdAt: "09 February 2024",
      description:
        "Data encryption is the process of turning data into encrypted data. It is a crucial technology for securing data while it is being stored or transmitted, especially in cloud environments where data is stored remotely and accessed over the internet. In our study, we compared five well-known algorithms to determine which is the most reliable for data encryption in cloud environments. This research study provides insights into the performance of different data encryption algorithms in the cloud environment and their potential applications in various industries. There are several benefits of using data encryption, including cost efficiency, management control and remote worker protection. Encryption is cheap to implement as many of today's devices and systems come with built-in encryption technology. Encryption can free organizations from government-imposed regulatory fines as some governments have mandated regulations and requirements regarding data protection that require private data to be encrypted and prevent all unauthorized access and all illegal activities. Encryption can help secure and protect remote workers by securing their data and communications. As more and more employees opt for remote working, that is, working from home, the need for data security has become more important.",
      url: "https://ieeexplore.ieee.org/document/10394865",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "my pic.jpg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours.",
  },
  blogSection: {
    title: "GitHub",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://github.com/Raja-25/",
    avatar_image_path: "github_logo.png",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  projectsdata,
};
