import React from "react";
import "./resumecont.css";
import { Fade } from "react-reveal";

export default function ResumeContainer(props) {
  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="rescont">
        <object className="iff" data={props.pdfUrl} aria-label=""></object>
      </div>
    </Fade>
  );
}
