import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import pdfUrl from "./Resume.pdf";
import ResumeContainer from "../../containers/ResumeContainer/ResumeContainer";
import "./Resume.css";
class Resume extends Component {
  render() {
    return (
      <div className="resume">
        <Header theme={this.props.theme} />
        <ResumeContainer theme={this.props.theme} pdfUrl={pdfUrl} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}
export default Resume;
